<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('categories', 'read')" type="is-secondary export" :loading="exporting" size="is-small" rounded outlined @click="exportCategories($event)">
				<svg-icon class="icon is-small" icon="export"></svg-icon>
				<span>Exportar</span>
			</b-button>
			<b-button v-if="permissionEnabled('categories', 'create')" type="is-primary create" rounded @click="createCategory($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>Agregar</span>
			</b-button>
		</Header>
		<section class="filter">
			<div class="filter__wrapper">
				<b-field label="Tipo" v-model="category.type" :label-position="label">
					<b-select placeholder="Grupo" @input="filterByType">
						<option selected value="2">Grupo</option>
						<option value="1">Criterios</option>
					</b-select>
				</b-field>
				<b-field>
					<b-input placeholder="Buscar" type="search" icon-right="magnify" @input="findByName" v-model="category.name"></b-input>
				</b-field>
			</div>
		</section>
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="!loading && !errored && categories.length <= 0" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group v-else name="filtering" class="filtering columns is-multiline" tag="div">
			<div v-for="c in categories" :key="c.id" class="column is-12-mobile is-6-tablet is-4-desktop">
				<article class="block" @click.self="updateCategory(c.id)">
					<div class="circle image is-48x48" :class="background(getWeight(c))" @click="updateCategory(c.id)">
						{{ getWeight(c) }}
					</div>
					<div class="block__content" @click="updateCategory(c.id)">
						<h3 class="block__name is-ellipsis is-capitalized" translate="no">{{ c.name }}</h3>
						<p class="block__email">{{ quantity(c.groups_count) }} • {{ timeTo(c.created_at) }}</p>
					</div>
					<Trigger :id="c.id" :role="c.name" :name="c.name" :active="c.active" />
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/User'
import Trigger from '@/components/triggers/Category'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Modal from '@/components/modals/Category'
import { create, update } from '@/mixins/modal'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import { mapGetters } from 'vuex'
import '@/mixins/date'
import actionUrl from '@/mixins/actionurl'

export default {
	components: {
		Layout,
		Header,
		Placeholder,
		Error,
		Results,
		Trigger,
		'svg-icon': Icon
	},
	mixins: [
		actionUrl
	],
	data() {
		return {
			label: 'on-border',
			exporting: false,
			errored: false,
			loading: true,
			pagination: 11,
			categories: [],
			category: {
				name: '',
				type: ''
			},
			typeRoute: 2
		}
	},
	methods: {
		getWeight(criteria) {
			let weight = 0

			if (criteria.type == 2) {
				// Grupo
				criteria.groups.forEach(c => {
					weight += this.extractWeight(c)
				})
			} else {
				// Categoria
				weight = this.extractWeight(criteria)
			}

			return weight
		},
		extractWeight(criteria) {
			let weight = 0

			if (criteria.equivalence == 0) {
				weight = criteria.weight
			} else {
				let eq = [...criteria.equivalences]
				weight = eq.pop().index
			}

			return parseInt(weight)
		},
		quantity(c) {
			let type = this.typeRoute == 1 ? ' grupo' : ' critério',
				suffix = c > 1 ? 's' : ''

			return c + type + suffix
		},
		background(p) {
			let r = this.typeRoute
			return r == 2 ? p <= 10 ? 'light' : p > 10 && p <= 21 ? 'medium' : 'dark' : p <= 3 ? 'light' : p > 3 && p < 6 ? 'medium' : 'dark'
		},
		getUrlParams() {
			const url = location.href.split('/')
			const params = url[4]
			const type = url.find(el => el === params)
			let id = null
			switch (type) {
				case 'create':
					this.createCategory()
					break
				case 'edit':
					id = url[5]

					if (id.includes('-')) {
						let values = id.split('-')

						this.typeRoute = parseInt(values[0])
						id = values[1]
					}

					this.updateCategory(parseInt(id))
					break
			}
		},
		createCategory() {
			if (this.permissionEnabled('categories', 'create')) {
				create('categories', Modal, 'New', this.typeRoute)
			}
		},
		updateCategory(id) {
			if (this.permissionEnabled('categories', 'edit')) {
				update('categories', id, Modal, 'Edit', null, this.typeRoute)
			}
		},
		async getAllCategories() {
			var route = this.typeRoute == 1 ? 'criteria' : 'criteria-group'
			this.loading = true
			try {
				const response = await Api.get(route + '/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.categories = data
					this.loading = false
					// this.joinGroupsToString()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async filterByType(value) {
			this.typeRoute = parseInt(value)
			this.getAllCategories()
		},
		async findByName() {
			try {
				const empty = /^\s*$/
				if (!empty.test(this.category.name)) {
					var route = this.typeRoute == 1 ? 'criteria' : 'criteria-group'
					const response = await Api.post(route + '/findByName', {
						name: this.category.name
					})
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.categories = data
						// this.joinGroupsToString()
					}
				} else {
					await this.getAllCategories()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async exportCategories() {
			this.exporting = true
			try {
				const response = await Api.post('criteria/export')
				const { status } = response
				if (status === 422) {
					errorToast('Se produjo un <strong>error</strong> al exportar los criterios.')
				} else {
					const { message } = response.data
					successToast('Los criterios se exportaron <strong>con éxito</strong>.')
					setTimeout(() => {
						this.exporting = false
						const node = document.createElement('a')
						node.href = message
						node.click()
					}, 2000)
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.exporting = false
			}
		},
		joinGroupsToString() {
			var weight = 0
			for (var i in this.categories) {
				this.categories[i].weight = this.categories[i].weight ? this.categories[i].weight : 0

				// Se for grupo, soma os critérios
				if (this.typeRoute == 2) {
					for (var j in this.categories[i].groups) {
						weight = parseInt(this.categories[i].groups[j].weight)

						this.categories[i].weight += weight ? weight : 0
					}
				}

				this.categories[i].groups = this.categories[i].groups.map(function(e) {
					return e.name
				}).join(',')
			}
		}
	},
	mounted() {
		this.getAllCategories()
		this.getUrlParams()
		eventHub.$on('reload-categories', () => {
			this.getAllCategories()
		})
		eventHub.$on('edit-modal-category', obj => {
			update('categories', obj.id, Modal, 'Edit', null, this.typeRoute)
		})
		eventHub.$on('delete-category', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: '¡Atención!',
				message: '<span>¿Realmente desea <br><strong>eliminar</strong> este criterio?</span> <small>Esta acción no se puede deshacer y tiene un impacto diferente en el funcionamiento del sistema.</small>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: 'No',
				confirmText: 'Sí',
				onConfirm: async () => {
					try {
						var route = this.typeRoute == 1 ? 'criteria' : 'criteria-group'
						const response = await Api.delete(route + `/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							successToast('¡El criterio fue <strong>eliminado</strong> exitosamente!')
							eventHub.$emit('reload-categories')
						}
					} catch (e) {
						console.log(e)
					}
				}
			})
		})
	},
	destroyed() {
		eventHub.$off('reload-categories')
		eventHub.$off('edit-modal-category')
		eventHub.$off('delete-category')
	},
	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
}
</script>
